import {useMemo, useState} from 'react';

type SortDirection = 'asc' | 'desc';

interface SortConfig {
    key: string;
    direction: SortDirection;
}

function useSortableData<T>(data: T[], initialSortConfig?: SortConfig) {
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(initialSortConfig || null);

    // Memoized sorted data
    const sortedData = useMemo(() => {
        if (!sortConfig) return data;

        const {key, direction} = sortConfig;
        const sorted = [...data].sort((a, b) => {
            const aValue = getNestedValue(a, key);
            const bValue = getNestedValue(b, key);

            // Ensure numeric comparison
            const aNum = isNaN(Number(aValue)) ? aValue.toString().toLowerCase() : Number(aValue);
            const bNum = isNaN(Number(bValue)) ? bValue.toString().toLowerCase() : Number(bValue);


            // Handle null, undefined, and empty string values
            if (aValue == null || aValue === "") return 1; // Move null/empty to the bottom
            if (bValue == null || bValue === "") return -1; // Move null/empty to the bottom


            if (aNum < bNum) return direction === 'asc' ? -1 : 1;
            if (aNum > bNum) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        return sorted;
    }, [data, sortConfig]);

    // Function to request a sort
    const requestSort = (key: string) => {
        setSortConfig((prev) => {
            if (prev?.key === key) {
                // Toggle direction for the same key
                return {key, direction: prev.direction === 'asc' ? 'desc' : 'asc'};
            }
            // Default to ascending
            return {key, direction: 'asc'};
        });
    };

    return {sortedData, sortConfig, requestSort};
}

// Utility function to get nested values
function getNestedValue<T>(obj: T, path: string): any {
    // @ts-ignore
    return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
}

export default useSortableData;
