import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useEffect, useRef, useState} from "react";
import {UsersEditPage} from "./subsection/UsersEditPage";
import useOnceEffect from "../hooks/userOnceEffect";
import {DSYMainWrapper, DSYMainWrapperInner, DSYSearchBar, DSYTable} from "../styles/GlobalStyles";
import {fetchUsers, toggleUserActive} from "../services/apiUsersService";
import {
    formatNumber,
    formatPhone,
    friendlyDate,
    sortItems,
    tableSortIconHeaderArrow,
    tableSortIconHeaderArrow2
} from "../components/Utils";
import {usersSelectedForCampaignId, usersUpdateSelectedForCampaignId} from "../services/apiCampaignsService";
import {CampaignsBulkUploader} from "./subsection/CampaignsBulkUploader";
import {DSYCampaign} from "../models/DSYCampaign";
import {DSYPlatforms, friendlyPlatformName} from "../config/consts";
import useSortableData from "../hooks/useSortableData";

const LazyLoadImage = require('react-lazy-load-image-component').LazyLoadImage;


export const renderTableHeader = (field: any, sortBy: string, handleSortByClick: (field: string) => void) => {
    if (field === null) {
        return null;
    }
    let active = sortBy === field['field'] || sortBy === field['field'] + '_desc';
    let canSort = field['sort'];
    let isDesc = (active && sortBy.endsWith('_desc'));
    const icon = active ? tableSortIconHeaderArrow(isDesc) : null;

    return (
        <th
            key={field['field']}
            style={
                canSort ?
                    {
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: active ? 'blue' : 'black'
                    } : {}}
            onClick={() => canSort ? handleSortByClick(field['field']) : null}>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
            }}>
                {field['title']}{icon ?? null}
            </div>
        </th>
    );
}


export function UsersPage({campaign, onCloseCallback, onUpdateItem}: {
    campaign?: any | null,
    onCloseCallback?: Function | null,
    onUpdateItem?: Function | null
}) {

    const [users, setUsers] = useState<any>([]);
    const [selectedUser, setSelectedUser] = useState<any>({});

    const searchRef = useRef<HTMLInputElement>(null);
    const searcTagsRef = useRef<HTMLInputElement>(null);
    const [sortBy, setSortBy] = useState('created_desc');
    const [refreshKey, setRefreshKey] = useState(0);

    const [userIdsSelectedToView, setUserIdsSelectedToView] = useState<any>([]);
    const [pickingUsersFilter, setPickingUsersFilter] = useState('all');

    const filterMinFollowerTikTok = useRef<HTMLInputElement>(null);
    const filterMaxFollowerTikTok = useRef<HTMLInputElement>(null);

    const filterMinFollowerIG = useRef<HTMLInputElement>(null);
    const filterMaxFollowerIG = useRef<HTMLInputElement>(null);

    const isSelectingUsersForCampaign = campaign != null;
    const [showBulkUploader, setShowBulkUploader] = useState(false);

    const editingCampaign: DSYCampaign | null = campaign != null ? campaign as DSYCampaign : null


    useOnceEffect(() => {

        const getUsers = async () => {

            if (isSelectingUsersForCampaign) {
                const visible = await usersSelectedForCampaignId(campaign['uuid'], {'uuid': campaign['uuid']});
                setUserIdsSelectedToView([]);
                if (visible.hasOwnProperty('ids')) {
                    let items = visible['ids'];
                    //filter out deactivated
                    // items = items.filter((id: any) => {
                    //     return parseInt(id['active'], 10) === 1;
                    // });
                    setUserIdsSelectedToView(items);
                }
            }

            try {
                const data = await fetchUsers();
                setUsers([]);
                if (data.hasOwnProperty('items')) {
                    let items = data['items'];
                    //filter out deactivated
                    items = items.filter((user: any) => {
                        return parseInt(user['active'], 10) === 1;
                    });
                    setUsers(items);

                }

            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        getUsers();
    });


    let onEditUser = (user: any) => {
        setSelectedUser(user);
        handleClickOpen();

    }

    const fullScreen = false;
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: object, reason: String) => {
        console.log("reason: " + reason);
        if (reason === 'backdropClick') {
            // return;
        }
        setOpen(false);
    };

    const hanleOnCloseRequest = (e: any) => {
        setOpen(false);
        handleSearch();
    }

    const handleSearch = () => {
        // let search = searchRef.current?.value;
        setRefreshKey(prev => prev + 1);
    }

    const handleToggleActive = async (user: any) => {
        //toggle active
        let isActive = parseInt(user.active, 10);
        let response = await toggleUserActive(user.uuid, isActive === 1 ? 0 : 1);
        if (response.status === 'error') {
            alert(response.message);
        } else {
            //update user property
            user.active = isActive === 1 ? 0 : 1;
            setUsers([...users]);
        }

        //
        // let newUsers = [...users];
        // let index = newUsers.findIndex((u: any) => u.uuid === user.uuid);
        // if (index > -1) {
        //     let newUser = {...newUsers[index]};
        //     newUser.active = !newUser.active;
        //     newUsers[index] = newUser;
        //     setUsers(newUsers);
        //     setFilteredUsers(newUsers);
        // }
    }
    const handleUpdateUser = (userUpdated: any) => {

        console.log('🔥update user', userUpdated);
        let index = users.findIndex((u: any) => u.uuid === userUpdated.uuid);
        if (index > -1) {
            let newUsers: any[] = [...users];

            // Create a new user object to ensure immutability
            newUsers[index] = {...userUpdated};
            console.log('newUsers', newUsers);
            // Update state with new user list
            // setUsers([]);
            setUsers(newUsers);
            // setFilteredUsers([]);
            // setFilteredUsers(newUsers);
            setRefreshKey(prev => prev + 1);
        }
    }


    const handleSortByClick = (field: string) => {
        if (sortBy === field) {
            setSortBy(field + '_desc');
        } else {
            setSortBy(field);
        }
        setRefreshKey(prev => prev + 1);

    }

    const handlePickingUsersFilter = async (filter: string) => {
        setPickingUsersFilter(filter);
        setRefreshKey(prev => prev + 1);

    }

    useEffect(() => {
        handleSearch();
    }, [pickingUsersFilter, sortBy, userIdsSelectedToView]);

    const totalUsers = (filter: string) => {
        let total = users.length;
        if (filter === 'selected') {
            return userIdsSelectedToView.length;
        }
        if (filter === 'not_selected') {
            return total - userIdsSelectedToView.length;
        }
        return total;
    }

    const handleUserSelectionSave = async (e: any) => {
        e.preventDefault();
        let sendValues = {'uuid': campaign['uuid'], 'ids': userIdsSelectedToView};
        console.log('save', sendValues);
        var result = await usersUpdateSelectedForCampaignId(campaign['uuid'], sendValues);
        console.log('saved', result);
        if (result.status === 'error') {
            alert(result.message);
        } else {
            if (onCloseCallback)
                onCloseCallback({'reload': true});
        }
    }


    const usersToShow = (value: number) => {
        let dataToUse = usersToShowProcessSubFilter(value);
        sortItems(sortBy, dataToUse);
        return dataToUse;
    }

    const isFiltered = () => {
        // if (isSelectingUsersForCampaign) {
        let minTikTokFollowers = parseInt(filterMinFollowerTikTok.current?.value || '0', 10);
        let maxTikTokFollowers = parseInt(filterMaxFollowerTikTok.current?.value || '10000000000', 10);

        let minIGFollowers = parseInt(filterMinFollowerIG.current?.value || '0', 10);
        let maxIGFollowers = parseInt(filterMaxFollowerIG.current?.value || '10000000000', 10);

        if (isNaN(minTikTokFollowers)) {
            minTikTokFollowers = 0;
        }
        if (isNaN(maxTikTokFollowers)) {
            maxTikTokFollowers = 10000000000;
        }

        if (isNaN(minIGFollowers)) {
            minIGFollowers = 0;
        }

        if (isNaN(maxIGFollowers)) {
            maxIGFollowers = 10000000000;
        }

        if ((minIGFollowers > 0 || maxIGFollowers < 10000000000) || (minTikTokFollowers > 0 || maxTikTokFollowers < 10000000000)) {
            return true;
        }
        // }
        return searchRef.current?.value || searcTagsRef.current?.value;
    }
    const usersToShowProcessSubFilter = (value: number) => {
        let dataToUse = usersToShowProcess(value);
        // if (isSelectingUsersForCampaign) {


        let minTikTokFollowers = parseInt(filterMinFollowerTikTok.current?.value || '0', 10);
        let maxTikTokFollowers = parseInt(filterMaxFollowerTikTok.current?.value || '10000000000', 10);
        if (isNaN(minTikTokFollowers)) {
            minTikTokFollowers = 0;
        }
        if (isNaN(maxTikTokFollowers)) {
            maxTikTokFollowers = 10000000000;
        }
        let minIGFollowers = parseInt(filterMinFollowerIG.current?.value || '0', 10);
        let maxIGFollowers = parseInt(filterMaxFollowerIG.current?.value || '10000000000', 10);
        if (isNaN(minIGFollowers)) {
            minIGFollowers = 0;
        }
        if (isNaN(maxIGFollowers)) {
            maxIGFollowers = 10000000000;
        }

        dataToUse = dataToUse.filter((user: any) => {
            return user.followers_tiktok >= minTikTokFollowers && user.followers_tiktok <= maxTikTokFollowers;
        });

        dataToUse = dataToUse.filter((user: any) => {
            return user.followers_instagram >= minIGFollowers && user.followers_instagram <= maxIGFollowers;
        });


        // }

        return dataToUse;

    }
    const usersToShowProcess = (value: number) => {

        let search = searchRef.current?.value;
        let searchTags = searcTagsRef.current?.value;
        let filtered = users;
        //remove deactivated
        if (isSelectingUsersForCampaign) {
            filtered = filtered.filter((user: any) => {
                return parseInt(user['active'], 10) === 1;
            });
        }


        if (isSelectingUsersForCampaign) {
            //filter by selected
            if (pickingUsersFilter === 'selected') {
                filtered = filtered.filter((user: any) => {
                    return userIdsSelectedToView.includes(user['uuid']);
                });
            } else if (pickingUsersFilter === 'not_selected') {
                filtered = filtered.filter((user: any) => {
                    return !userIdsSelectedToView.includes(user['uuid']);
                });
            }
        }

        if (!search && !searchTags) {
            return filtered;
        }

        if (search) {
            let term = search.toLowerCase();
            let fieldsToSearch = ['first_name', 'last_name', 'email', 'handle_tiktok', 'handle_instagram', 'handle_snapchat'];
            filtered = filtered.filter((user: any) => {
                if (user != null) {
                    let match = false;
                    fieldsToSearch.forEach((field) => {
                        if (user[field]) {
                            if (user[field].toLowerCase().includes(term)) {
                                match = true;
                            }
                        }
                    });
                    if (match) {
                        return true;
                    }
                }
                return false;
            });
        }

        if (searchTags) {
            let term = searchTags.toLowerCase();
            let fieldsToSearch = ['tags'];
            filtered = filtered.filter((user: any) => {
                if (user != null) {
                    let match = false;
                    fieldsToSearch.forEach((field) => {
                        if (user[field]) {
                            let tags = user[field];
                            let found = tags.find((tag: any) => tag.name.toLowerCase().includes(term));
                            if (found) {
                                match = true;
                            }
                        }
                    });
                    if (match) {
                        return true;
                    }
                }
                return false;
            });
        }
        //sort
        return filtered;
    }


    function handleBulkImport(items: any) {
        if (editingCampaign === null) {
            return;
        }
        console.log('bulk import', items);
        //items will be a list of tiktok handles
        let usersToSelect: any[] = [];
        const isTikTok = editingCampaign!.platforms_id === DSYPlatforms.TIKTOK;
        const isIG = editingCampaign!.platforms_id === DSYPlatforms.INSTAGRAM;

        if (items && items.length > 0) {
            items.forEach(async (item: any) => {
                if (isTikTok) {
                    let found = users.find((user: any) => user.handle_tiktok?.toString().toLowerCase().trim() === item);
                    if (found) {
                        usersToSelect.push(found.uuid);
                    }
                } else if (isIG) {
                    let found = users.find((user: any) => user.handle_instagram === item);
                    if (found) {
                        usersToSelect.push(found.uuid);
                    }
                }
            });
            if (usersToSelect.length > 0) {
                setUserIdsSelectedToView([...userIdsSelectedToView, ...usersToSelect]);
                setRefreshKey(prev => prev + 1);
            }
        }
        setShowBulkUploader(false);
    }


    const {sortedData, sortConfig, requestSort} = useSortableData(usersToShow(refreshKey), {
        key: 'created',
        direction: 'desc'
    });

    //RENDER


    if (isSelectingUsersForCampaign) {
        return <>
            <DSYMainWrapperInner style={{
                minHeight: '90vh',
                height: '90vh',
                minWidth: '80vw',

            }}>
                <h2>Users</h2>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    backgroundColor: '#f3f3f3',
                    padding: '10px',
                }}>
                    <div>
                        <DSYSearchBar>
                            <input type="text" ref={searchRef} placeholder="Search email, name, handle, ..."
                                   onKeyUp={handleSearch}/>
                        </DSYSearchBar>
                    </div>
                    <div>
                        <DSYSearchBar>
                            <input type="text" ref={searcTagsRef} placeholder="Search Tags..."
                                   onKeyUp={handleSearch}/>
                        </DSYSearchBar>
                    </div>
                    <div style={{
                        marginTop: '-18px',
                    }}>
                        <div style={{
                            fontSize: '11px',
                        }}>
                            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                <div> TikTok:</div>
                                <input type="number" ref={filterMinFollowerTikTok} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Min"/>

                                <input type="number" ref={filterMaxFollowerTikTok} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Max"/>

                            </div>
                        </div>
                    </div>
                    <div style={{
                        marginTop: '-18px',
                    }}>
                        <div style={{
                            fontSize: '11px',
                        }}>
                            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                <div> IG:</div>
                                <input type="number" ref={filterMinFollowerIG} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Min"/>

                                <input type="number" ref={filterMaxFollowerIG} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Max"/>

                            </div>
                        </div>
                    </div>

                    {isFiltered() && <div style={{
                        padding: '10px',
                        marginTop: '-15px',
                    }}>
                        <button onClick={() => {
                            handleClearFilter();
                        }}>Clear Filter
                        </button>
                    </div>
                    }

                </div>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    backgroundColor: '#f3f3f3',
                    padding: '10px',

                }}>
                    Filter by: {
                    [
                        {'title': 'All', 'value': 'all'},
                        {'title': 'Selected', 'value': 'selected'},
                        {'title': 'Not Selected', 'value': 'not_selected'},
                    ].map((field) => {
                            //radio
                            return (
                                <div
                                    key={field['value']}
                                    style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '5px',
                                        gap: '5px',

                                    }}>
                                    <label key={field['value']} style={{
                                        marginRight: '10px',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        gap: '5px',
                                        justifyContent: 'center',
                                    }}>
                                        <input type="radio" name="filter" value={field['value']}
                                               checked={pickingUsersFilter === field['value']}
                                               onChange={(e) => {
                                               }}
                                               onClick={() => {
                                                   handlePickingUsersFilter(field['value']);
                                               }}
                                        />
                                        <div>{totalUsers(field['value'])} {field['title']}</div>
                                    </label>
                                </div>
                            )
                                ;
                        }
                    )
                }
                    {editingCampaign && <>
                        <button onClick={() => {
                            setShowBulkUploader(true);
                        }}>Bulk User Selection ({friendlyPlatformName(editingCampaign.platforms_id)})
                        </button>
                    </>}

                </div>

                <br/>

                <button
                    style={{
                        padding: '10px 30px',
                        backgroundColor: 'green',
                        color: 'white',
                        cursor: 'pointer',
                        border: 'none',
                        fontWeight: 'bold',
                        borderRadius: '5px',
                        marginBottom: '10px',
                    }}
                    onClick={handleUserSelectionSave}>Save Changes
                </button>
                {isFiltered() && <div style={{
                    backgroundColor: '#ff9',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                }}>
                    <h3>Filtered Results</h3>
                    <button onClick={() => {
                        let uuidsTotAdd: any[] = [];
                        usersToShow(refreshKey).forEach((user: any) => {
                                if (!userIdsSelectedToView.includes(user['uuid'])) {
                                    uuidsTotAdd.push(user['uuid']);
                                }
                            }
                        );
                        setUserIdsSelectedToView([...userIdsSelectedToView, ...uuidsTotAdd]);
                        setRefreshKey(prev => prev + 1);
                    }}>Select All
                    </button>
                    <button onClick={() => {
                        let uuidsToRemove: any[] = [];
                        usersToShow(refreshKey).forEach((user: any) => {
                                if (userIdsSelectedToView.includes(user['uuid'])) {
                                    uuidsToRemove.push(user['uuid']);
                                }
                            }
                        );
                        setUserIdsSelectedToView(userIdsSelectedToView.filter((id: any) => !uuidsToRemove.includes(id)));
                        setRefreshKey(prev => prev + 1);
                    }}>Deselect All
                    </button>
                </div>
                }
                <div style={{fontSize: '13px'}}>Items: {usersToShow(refreshKey).length}</div>

                {showBulkUploader && editingCampaign && <Dialog open={true} onClose={() => {
                }}>
                    <DialogContent>
                        <CampaignsBulkUploader
                            editingCampaign={editingCampaign}
                            onClose={(items: any) => {
                                handleBulkImport(items);
                            }}/>
                    </DialogContent>
                </Dialog>
                }

                {open && <Dialog
                    fullScreen={fullScreen}
                    open={open}

                    // fullWidth={true}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        {selectedUser && <UsersEditPage user={selectedUser}
                                                        onUpdateUser={handleUpdateUser}
                                                        onCloseCallback={hanleOnCloseRequest}/>}
                    </DialogContent>

                </Dialog>}
                <DSYTable>
                    <thead>
                    <tr>{
                        [
                            {'title': 'Options', 'field': 'options', 'sort': false},
                            {'title': 'Boosts', 'field': 'boosted_count', 'sort': true},
                            {'title': 'First Name', 'field': 'first_name', 'sort': true},
                            {'title': 'Last Name', 'field': 'last_name', 'sort': true},
                            {'title': 'Image', 'field': 'img_url', 'sort': false},
                            {'title': 'Email', 'field': 'email', 'sort': true},
                            {'title': 'Phone', 'field': 'phone', 'sort': true, 'nowrap': true},
                            {'title': 'Tags', 'field': 'tags', 'sort': false},
                            {'title': 'TikTok', 'field': 'handle_tiktok', 'sort': true},
                            {'title': 'TT Followers', 'field': 'followers_tiktok', 'sort': true},
                            {'title': 'Instagram', 'field': 'handle_instagram', 'sort': true},
                            {'title': 'IG Followers', 'field': 'followers_instagram', 'sort': true},
                            {'title': 'Snap', 'field': 'handle_snapchat', 'sort': true},
                            {'title': 'Stripe', 'field': 'has_stripe_account_id', 'sort': true},
                            {'title': 'Created', 'field': 'created', 'sort': true},
                        ].map((field) => {
                                return renderTableHeader(field, sortBy, handleSortByClick);
                            }
                        )
                    }
                    </tr>
                    </thead>
                    <tbody>
                    {usersToShow(refreshKey) && usersToShow(refreshKey).map((user: any) => {
                        let isSelected = userIdsSelectedToView.includes(user['uuid']);
                        let isDeactivated = parseInt(user['active'], 10) === 0;
                        let boostCount = parseInt(user['boosted_count'], 10);
                        let boostCash = parseFloat(user['boosted_total_cash']);
                        return (
                            <tr key={user['uuid']} style={
                                isDeactivated ? {
                                        backgroundColor: '#ccc',
                                    } :
                                    isSelected ? {
                                        backgroundColor: 'lightyellow',
                                    } : {}
                            }>
                                <td>
                                    <input type={"checkbox"} checked={isSelected}
                                           onChange={(e) => {
                                           }}
                                           onClick={() => {
                                               //toggle
                                               let newSelected = [...userIdsSelectedToView];
                                               if (isSelected) {
                                                   newSelected = newSelected.filter((id: any) => id !== user['uuid']);
                                               } else {
                                                   newSelected.push(user['uuid']);
                                               }
                                               setUserIdsSelectedToView(newSelected);

                                           }}/>
                                </td>
                                <td>{
                                    boostCount > 0 ? <div><a
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            padding: '5px',
                                            fontSize: '14px'
                                        }}
                                        href="#" onClick={(e) => {
                                        e.preventDefault();
                                        //open new window
                                        window.open('/users/' + user['uuid'] + '/boosts/', '_blank');
                                    }}>{formatNumber(user['boosted_count'])}</a>
                                        <span style={{
                                            padding: '5px',
                                            opacity: 0.7,
                                            color: boostCash > 0 ? 'green' : 'black',
                                        }}>${formatNumber(boostCash.toFixed(2))}</span>
                                    </div> : formatNumber(user['boosted_count'])
                                }</td>

                                <td>{user['first_name']}</td>
                                <td>{user['last_name']}</td>
                                <td>{
                                    user['img_url'] &&
                                    <LazyLoadImage alt={''}
                                                   loading={'lazy'}
                                                   src={user['img_url']} width={30} height={30} style={{
                                        objectFit: 'scale-down',
                                    }}/>
                                }</td>
                                <td style={{whiteSpace: 'nowrap'}}>{user['email']}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{
                                    formatPhone(user['phone'])
                                }</td>
                                <td>
                                    <div style={{
                                        maxWidth: '200px',
                                        overflow: 'scroll',
                                    }}>
                                        {user.tags && user.tags.map((tag: any) => {
                                                const randomKey = Math.random().toString(36).substring(7);
                                                return <span
                                                    key={randomKey}
                                                    style={{
                                                        padding: '3px 8px',
                                                        margin: '2px',
                                                        backgroundColor: "#87CEFA44",
                                                        borderRadius: '25px',

                                                    }}>{tag.name}</span>;
                                            }
                                        )}
                                    </div>
                                </td>

                                <td style={{whiteSpace: 'nowrap'}}>{
                                    user['handle_tiktok'] ? <a href={'https://www.tiktok.com/@' + user['handle_tiktok']}
                                                               referrerPolicy={'no-referrer'}
                                                               target={'_blank'}>{user['handle_tiktok']}</a> : '--'

                                }</td>
                                <td>{formatNumber(user['followers_tiktok'])}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{
                                    user['handle_instagram'] ?
                                        <a href={'https://www.instagram.com/' + user['handle_instagram']}
                                           referrerPolicy={'no-referrer'}
                                           target={'_blank'}>{user['handle_instagram']}</a> : '--'
                                }</td>


                                <td>{formatNumber(user['followers_instagram'])}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{user['handle_snapchat'] ?
                                    <a href={'https://www.snapchat.com/add/' + user['handle_snapchat']}
                                       referrerPolicy={'no-referrer'}
                                       target={'_blank'}>{user['handle_snapchat']}</a> : '--'}</td>
                                <td>{(user['stripe_account_id'] && (user['stripe_account_id']).length > 8) &&
                                    <span
                                        style={{
                                            backgroundColor: 'rgba(0,255,0,0.2)',
                                            padding: '5px',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                        }}
                                    >Yes</span>}</td>

                                <td style={{whiteSpace: 'nowrap'}}>{friendlyDate(user['created'])}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </DSYTable>
            </DSYMainWrapperInner>
        </>

    }


    //==================================================================================================
    // The following code is for the UsersPage component
    //==================================================================================================

    function handleClearFilter() {
        searchRef.current!.value = '';
        searcTagsRef.current!.value = '';
        try {
            filterMinFollowerTikTok.current!.value = '';
            filterMaxFollowerTikTok.current!.value = '';
            filterMinFollowerIG.current!.value = '';
            filterMaxFollowerIG.current!.value = '';
        } catch (e) {
            console.log('error', e);
        }
        setRefreshKey(prev => prev + 1);
    }

    return (
        <DSYMainWrapper>
            <DSYMainWrapperInner>

                <h2>Users</h2>
                <div style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    backgroundColor: '#f3f3f3',
                    padding: '10px',
                }}>
                    <div>
                        <DSYSearchBar>
                            <input type="text" ref={searchRef} placeholder="Search email, name, handle, ..."
                                   onKeyUp={handleSearch}/>
                        </DSYSearchBar>
                    </div>
                    <div>
                        <DSYSearchBar>
                            <input type="text" ref={searcTagsRef} placeholder="Search Tags..."
                                   onKeyUp={handleSearch}/>
                        </DSYSearchBar>
                    </div>
                    <div style={{
                        marginTop: '-18px',
                    }}>
                        <div style={{
                            fontSize: '11px',
                        }}>
                            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                <div> TikTok:</div>
                                <input type="number" ref={filterMinFollowerTikTok} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Min"/>

                                <input type="number" ref={filterMaxFollowerTikTok} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Max"/>

                            </div>
                        </div>
                    </div>
                    <div style={{
                        marginTop: '-18px',
                    }}>
                        <div style={{
                            fontSize: '11px',
                        }}>
                            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                <div> IG:</div>
                                <input type="number" ref={filterMinFollowerIG} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Min"/>

                                <input type="number" ref={filterMaxFollowerIG} min={0} max={1000000}
                                       onChange={(e) => handleSearch()}
                                       placeholder="Max"/>

                            </div>
                        </div>
                    </div>
                    {isFiltered() && <div style={{
                        padding: '10px',
                        marginTop: '-15px',
                    }}>
                        <button onClick={() => {
                            handleClearFilter();
                        }}>Clear Filter
                        </button>
                    </div>
                    }
                </div>
                {open && <Dialog
                    fullScreen={fullScreen}
                    open={open}

                    // fullWidth={true}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        {selectedUser && <UsersEditPage user={selectedUser}
                                                        onUpdateUser={handleUpdateUser}
                                                        onCloseCallback={hanleOnCloseRequest}/>}
                    </DialogContent>
                    {/*<DialogActions>*/}
                    {/*    <Button autoFocus onClick={hanleOnClickToClose}>*/}
                    {/*        Disagree*/}
                    {/*    </Button>*/}
                    {/*    <Button onClick={hanleOnClickToClose} autoFocus>*/}
                    {/*        Agree*/}
                    {/*    </Button>*/}
                    {/*</DialogActions>*/}
                </Dialog>}
                <DSYTable>
                    <thead>
                    <tr>{
                        [
                            {'title': 'Options', 'field': 'options', 'sort': false},
                            {'title': 'Boosts', 'field': 'boosted_count', 'sort': true},
                            {'title': 'First Name', 'field': 'first_name', 'sort': true},
                            {'title': 'Last Name', 'field': 'last_name', 'sort': true},
                            {'title': 'Image', 'field': 'img_url', 'sort': false},
                            {'title': 'Email', 'field': 'email', 'sort': true},
                            {'title': 'Phone', 'field': 'phone', 'sort': true, 'nowrap': true},
                            {'title': 'Tags', 'field': 'tags', 'sort': false},
                            {'title': 'TikTok', 'field': 'handle_tiktok', 'sort': true},
                            {'title': 'TT Followers', 'field': 'followers_tiktok', 'sort': true},
                            {'title': 'Instagram', 'field': 'handle_instagram', 'sort': true},
                            {'title': 'IG Followers', 'field': 'followers_instagram', 'sort': true},
                            // {'title': 'Push Token', 'field': 'has_push_notification_token', 'sort': false},
                            {'title': 'Snap', 'field': 'handle_snapchat', 'sort': true},
                            {'title': 'Stripe', 'field': 'has_stripe_account_id', 'sort': true},
                            {'title': 'Created', 'field': 'created', 'sort': true},
                            {'title': 'Active', 'field': 'active', 'sort': false},
                        ].map((header) => {

                                const isSorted = sortConfig?.key === header.field;
                                const icon = isSorted ? tableSortIconHeaderArrow2(sortConfig?.direction) : null;
                                return (
                                    <th
                                        style={{
                                            cursor: header.sort ? 'pointer' : 'default'
                                        }}
                                        key={header.field} className={isSorted ? 'selected' : ''}
                                        onClick={() => {
                                            if (header.sort) {
                                                requestSort(header.field);
                                            }
                                        }}>
                                        {header.title}
                                        {icon ?? null}
                                    </th>
                                );
                                // return renderTableHeader(header, sortBy, handleSortByClick);
                            }
                        )
                    }
                    </tr>
                    </thead>
                    <tbody>
                    {usersToShow(refreshKey) && sortedData.map((user: any) => {
                        let isDeactivated = parseInt(user['active'], 10) === 0;
                        let keyHash = user['uuid'];
                        let boostCount = parseInt(user['boosted_count'], 10);
                        let boostCash = parseFloat(user['boosted_total_cash']);
                        return (
                            <tr key={keyHash}
                                style={
                                    isDeactivated ? {
                                        backgroundColor: '#ccc',
                                    } : {}}>
                                <td>
                                    <button onClick={() => {
                                        onEditUser(user);
                                    }}>Edit
                                    </button>
                                </td>
                                <td>{
                                    boostCount > 0 ? <div><a
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            padding: '5px',
                                            fontSize: '14px'
                                        }}
                                        href="#" onClick={(e) => {
                                        e.preventDefault();
                                        //open new window
                                        window.open('/users/' + user['uuid'] + '/boosts/', '_blank');
                                    }}>{formatNumber(user['boosted_count'])}</a>
                                        <span style={{
                                            padding: '5px',
                                            opacity: 0.7,
                                            color: boostCash > 0 ? 'green' : 'black',
                                        }}>${formatNumber(boostCash.toFixed(2))}</span>
                                    </div> : formatNumber(user['boosted_count'])
                                }</td>
                                <td>{user['first_name']}</td>
                                <td>{user['last_name']}</td>
                                <td>{
                                    user['img_url'] &&
                                    <LazyLoadImage alt={''}
                                                   loading={'lazy'}
                                                   src={user['img_url']} width={30} height={30} style={{
                                        objectFit: 'scale-down',
                                    }}/>
                                }</td>
                                <td style={{whiteSpace: 'nowrap'}}>{user['email']}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{
                                    formatPhone(user['phone'])
                                }</td>
                                <td>
                                    <div style={{
                                        maxWidth: '200px',
                                        overflow: 'scroll',
                                    }}>
                                        {user.tags && user.tags.map((tag: any) => {
                                                const randomKey = Math.random().toString(36).substring(7);
                                                return <span
                                                    key={randomKey}
                                                    style={{
                                                        padding: '3px 8px',
                                                        margin: '2px',
                                                        backgroundColor: "#87CEFA44",
                                                        borderRadius: '25px',

                                                    }}>{tag.name}</span>;
                                            }
                                        )}
                                    </div>
                                </td>
                                <td style={{whiteSpace: 'nowrap'}}>{
                                    user['handle_tiktok'] ? <a href={'https://www.tiktok.com/@' + user['handle_tiktok']}
                                                               referrerPolicy={'no-referrer'}
                                                               target={'_blank'}>{user['handle_tiktok']}</a> : '--'

                                }</td>
                                <td>{formatNumber(user['followers_tiktok'])}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{
                                    user['handle_instagram'] ?
                                        <a href={'https://www.instagram.com/' + user['handle_instagram']}
                                           referrerPolicy={'no-referrer'}
                                           target={'_blank'}>{user['handle_instagram']}</a> : '--'
                                }</td>

                                <td>{formatNumber(user['followers_instagram'])}</td>
                                <td style={{whiteSpace: 'nowrap'}}>{user['handle_snapchat'] ? <a
                                    href={'https://www.snapchat.com/add/' + user['handle_snapchat']}
                                    referrerPolicy={'no-referrer'}
                                    target={'_blank'}>{user['handle_snapchat']}</a> : '--'
                                }</td>
                                <td>{(user['stripe_account_id'] && (user['stripe_account_id']).length > 8) &&
                                    <span
                                        style={{
                                            backgroundColor: 'rgba(0,255,0,0.2)',
                                            padding: '5px',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                        }}
                                    >Yes</span>}</td>

                                {/*<td>{user['has_push_notification_token']}</td>*/}

                                <td style={{whiteSpace: 'nowrap'}}>{friendlyDate(user['created'])}</td>
                                {/*<td>{JSON.stringify(user)}</td>*/}
                                <td>
                                    <button
                                        onClick={() => {
                                            handleToggleActive(user);
                                        }}
                                    >{isDeactivated ? 'Restore Account' : 'Suspend'}</button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </DSYTable>
            </DSYMainWrapperInner>
        </DSYMainWrapper>
    );
}