import {apiClient} from "./apiService";

export const fetchBoosts = async () => {
    const response = await apiClient.get('/boosts/all');
    return response.data;
};

export const fetchBoostsForCampaignUUID = async (uuid: string) => {
    const response = await apiClient.post('/boosts/campaign/' + uuid, {'uuid': uuid});
    return response.data;
};

export const updateBoostStatus = async (id: string, data: any) => {
    const response = await apiClient.post('/boosts/update/' + id, data);
    return response.data;
}


export const boostByUserUUID = async (uuid: string) => {
    const response = await apiClient.post('/boosts/by-user/' + uuid, {'uuid': uuid});
    return response.data;
};


export const sendPaypalPaymentForBoost = async (id: string, data: any) => {
    const response = await apiClient.post('/boosts/paypal/', {
        'uuid': id,
        'action': 'send-payment',
        'payload': data
    });
    return response.data;
}