import {SetStateAction, useEffect, useState} from "react";
import {DSYForm, DSYTable} from "../../styles/GlobalStyles";
import {updateUser} from "../../services/apiUsersService";
import imageCompression from "browser-image-compression";
import {formatPhone} from "../../components/Utils";
import {Autocomplete, Box, Chip, CircularProgress, TextField} from "@mui/material";
import {tagsSearch} from "../../services/apiTags";

export function UsersEditPage({user, onCloseCallback, onUpdateUser}: {
    user: any,
    onCloseCallback: Function,
    onUpdateUser: Function
}) {


    let sectionsToEdit = [
        {'title': 'First Name', 'input_type': 'text', 'value': '', 'name': 'first_name'},
        {'title': 'Last Name', 'input_type': 'text', 'value': '', 'name': 'last_name'},
        {'title': 'Email', 'input_type': 'email', 'value': '', 'name': 'email'},
        {'title': 'Image', 'input_type': 'file', 'accept_types': 'image/*', 'value': '', 'name': 'img_url'},
        {'title': 'Phone', 'input_type': 'tel', 'value': '', 'name': 'phone'},
        {'title': 'TikTok', 'input_type': 'text', 'value': '', 'name': 'handle_tiktok'},
        {'title': 'Instagram', 'input_type': 'text', 'value': '', 'name': 'handle_instagram'},
        {'title': 'Snapchat', 'input_type': 'text', 'value': '', 'name': 'handle_snapchat'},
        // {'title': 'Active', 'input_type': 'checkbox', 'value': 'checked', 'name': 'active'},
    ]

    const [didLoadData, setDidLoadData] = useState(false);
    const [values, setValues] = useState<any>({});

    const [tags, setTags] = useState<any[]>([]); // All fetched tags
    const [selectedTags, setSelectedTags] = useState<any[]>([]); // Tags user selected
    const [loading, setLoading] = useState(false); // Loading state for autocomplete
    const [inputValue, setInputValue] = useState(''); // Input text
    const [queryTicket, setQueryTicket] = useState(0);

    useEffect(() => {
        let data: any = {};
        sectionsToEdit.forEach((section) => {
            data[section.name] = user[section.name];
        });
        setValues(data);
        setDidLoadData(true);

        //if user has tags, set them
        if (user.tags) {
            setSelectedTags(user.tags);
        }

    }, []);


    useEffect(() => {
        if (inputValue === '') {
            return;
        }
        setLoading(true);
        let ticket = queryTicket + 1;
        setQueryTicket(queryTicket + 1);
        tagsSearch(inputValue, ticket).then((data) => {

                if (data.payload) {
                    let responseTicket = data.payload.ticket;
                    if (responseTicket !== ticket) {
                        console.log('ticket MISMATCH', responseTicket, ticket);
                        return;
                    }
                    let realTags = data.payload.tags;
                    console.log('realTags', realTags);
                    setTags(realTags);
                    setLoading(false);

                }

            }
        );
    }, [inputValue]);

    useEffect(() => {
        // Set the selected tags
        setInputValue('');
    }, [selectedTags]);

    const handleTagChange = (event: any, newValue: SetStateAction<any[]>) => {

        if (newValue) {
            // @ts-ignore
            if (newValue.id) {
                setSelectedTags([...selectedTags, newValue]);
            }

        }

    };

    const handleKeyDownOnTags = (event: any) => {
        if (event.key === 'Enter' && inputValue !== '') {
            console.log('handleKeyDownOnTags');
            // Prevent the default behavior of the enter key in Autocomplete
            event.preventDefault();

            let random = 'new_tag_id_' + Math.random();

            // Check if the tag already exists
            if (selectedTags.find((tag) => tag.name.toLowerCase() === inputValue.toLowerCase())) {
                console.log('Tag already exists');
                return;
            }
            // if (!selectedTags.includes(inputValue)) {
            // Add the new tag to the selected tags list
            let newTag = {id: random, name: inputValue};
            setSelectedTags([...selectedTags, newTag]);
            setInputValue('');
        }
    };


    const handleImageChange = async (e: any) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {


            const options = {
                maxSizeMB: 1, // Maximum file size (in MB)
                maxWidthOrHeight: 1200, // Maximum dimensions
                // onProgress: (e) => console.log(e)
            };
            //temp, show loading
            try {

                const compressedFile = await imageCompression(file, options);
                if (compressedFile) {
//update image
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setValues({...values, 'img_url': reader.result});
                    };
                    reader.readAsDataURL(compressedFile);
                    // let response = await serviceUploadAsset(compressedFile, {});
                    // if (response && response.success) {
                    //     let path = response.path;
                    //     setAssetPath(path);
                    //     onValueChange(path);
                    // }
                    // setIsUploading(false
                } else {

                }
                // const reader = new FileReader();
                // reader.onloadend = () => {
                //     setImageBase64(reader.result);
                //     onValueChange(reader.result);
                // };
                // reader.readAsDataURL(compressedFile);

            } catch (error) {
                console.error(error);

            }

        }
    };
    const handleCancel = (e: any) => {
        e.preventDefault();
        onCloseCallback();
    }

    const handleSave = async (e: any) => {
        e.preventDefault();

        //phone must start with +
        if (values.phone != null && values.phone.length > 0 && !values.phone.startsWith('+')) {
            alert('Phone must start with +');
            return;
        }
        let sendValues = {...values};
        sendValues.uuid = user.uuid;
        sendValues.tags = selectedTags ?? [];
        console.log('sendValues', sendValues);


        var result = await updateUser(user.uuid, sendValues);
        console.log('saved', result);
        if (result.status === 'error') {
            alert(result.message);
        } else {
            user = {...user, ...values};
            try {
                let updatedTags = result.tags;
                console.log('updatedTags', updatedTags);
                // Update the tags for the user
                user.tags = updatedTags;
                console.log('user', user);
                user = {...user, tags: updatedTags};
            } catch (e) {
                console.error('error updating tags', e);
            }
            onUpdateUser(user);
            onCloseCallback();
        }
    }

    if (!didLoadData) {
        return <>
            Loading...
        </>
    }
    return (
        <div>
            <div style={{
                display: 'flex',
                gap: 20,
                alignItems: 'center',
                marginBottom: 20
            }}>
                <img src={values.img_url} width={130} height={130} style={{
                    objectFit: 'cover',
                    aspectRatio: '1/1',
                    borderRadius: '10%',
                    boxShadow: '0px 0px 5px 0px #ccc',
                }}/>
                <input type={'file'} accept={'image/*'} onChange={handleImageChange}/>
            </div>
            <h2>Edit </h2>
            <DSYForm onSubmit={handleSave}>
                <DSYTable>
                    {sectionsToEdit.map((section, index) => {
                        let inner = null;
                        if (section.input_type === 'checkbox') {
                            inner = <input type={section.input_type} name={section.name}
                                           checked={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.checked})
                                           }}/>
                        } else if (section.input_type === 'file') {
                            return <></>
                            // inner = <><input type={section.input_type} name={section.name}
                            //                  accept={section.accept_types}
                            //                  onChange={handleImageChange}/>
                            //
                            //     <img src={values[section.name]} width={130} height={130} style={{
                            //         objectFit: 'scale-down',
                            //     }}/>
                            // </>
                        } else {
                            inner = <input type={section.input_type} name={section.name}
                                           value={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.value})
                                           }}/>
                        }

                        //special case for tiktok and instagram
                        if (section.name === 'handle_tiktok' && values[section.name] != null && values[section.name].toString().length > 0) {
                            inner = <>
                                <input type={section.input_type} name={section.name}
                                       disabled={true}
                                       value={values[section.name]}
                                />
                                <div style={{
                                    padding: 10,
                                }}>
                                    <a href={'https://www.tiktok.com/@' + values[section.name]}
                                       referrerPolicy={'no-referrer'}
                                       target="_blank">https://www.tiktok.com/@{values[section.name]}</a>
                                </div>
                            </>
                        } else if (section.name === 'handle_instagram' && values[section.name] != null && values[section.name].toString().length > 0) {
                            inner = <>{inner}
                                <div style={{
                                    padding: 10,
                                }}>
                                    <a href={'https://www.instagram.com/' + values[section.name]}
                                       referrerPolicy={'no-referrer'}
                                       target="_blank">https://www.instagram.com/{values[section.name]}</a>
                                </div>
                            </>
                        } else if (section.name === 'handle_snapchat' && values[section.name] != null && values[section.name].toString().length > 0) {
                            inner = <>{inner}
                                <div style={{
                                    padding: 10,
                                }}>
                                    <a href={'https://www.snapchat.com/add/' + values[section.name]}
                                       referrerPolicy={'no-referrer'}
                                       target="_blank">https://www.snapchat.com/add/{values[section.name]}</a>
                                </div>
                            </>
                        } else if (section.name === 'phone') {
                            inner = <>{inner}
                                {formatPhone(values[section.name])}
                            </>
                        }

                        return (
                            <tr key={index}>
                                <td>{section.title}</td>
                                <td>
                                    {inner}
                                </td>
                            </tr>
                        );
                    })}
                </DSYTable>
                <div style={{
                    marginTop: 20,
                }}>
                    <h3 style={{
                        marginBottom: 10
                    }}>Tags</h3>
                    <div style={{
                        maxWidth: 400,
                    }}>
                        <Box sx={{mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1}}>
                            {selectedTags.map((tag, index) => {
                                    let name = tag.name ?? '';

                                    return <Chip
                                        key={index}
                                        label={name}
                                        onDelete={() => {
                                            // Remove the tag when "X" is clicked
                                            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
                                        }}
                                    />
                                }
                            )}
                        </Box>
                        {selectedTags.length > 0 && <div style={{
                            margin: '20px 0'
                        }}></div>}

                        <Autocomplete
                            freeSolo
                            getOptionLabel={(option) => {
                                // For freeSolo, option can be either a string or an object
                                return typeof option === 'string' ? option : option.name;
                            }}
                            options={tags}
                            // value={selectedTags.map(
                            //     (tag) => {
                            //         return tag.name;
                            //     }
                            // )} // Value is the selected tags


                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            onChange={(event, newValue) => handleTagChange(event, newValue)}

                            onKeyDown={handleKeyDownOnTags}
                            filterSelectedOptions

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Search or Add Tags"
                                    minRows={3}
                                    multiline={true}
                                    placeholder="Type to search or add tags"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                    </div>

                </div>
                <hr style={{
                    margin: '20px 0'
                }}/>
                <div className={'cta-area'}>
                    <a onClick={handleCancel}>Cancel</a>
                    <button type="submit">Submit</button>
                </div>
            </DSYForm>
        </div>
    );
}