import parsePhoneNumberFromString from "libphonenumber-js";
import moment from "moment";
import {MoveDown, MoveUp} from "lucide-react";


export const formatPhoneNumber = (phoneNumber: string) => {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);

    if (!phoneNumberObject) {
        return phoneNumber; // Return the original input if parsing fails
    }

    const countryCode = phoneNumberObject.countryCallingCode;
    const nationalNumber = phoneNumberObject.formatNational().replace(/\s+/g, '-');

    return `+${countryCode}-${nationalNumber}`;
};

export const formatPhone = (phone?: string) => {
    if (!phone) {
        return phone;
    }
    return formatPhoneNumber(phone);
}

export const formatNumber = (value: any) => {
    const number = parseFloat(value) ?? 0;
    if (isNaN(number)) {
        return '--';
    }
    const formattedNumber = new Intl.NumberFormat('en-US').format(number);

    return (
        formattedNumber
    );
};

export const friendlyDate = (val?: string, format?: string) => {
    if (!val) {
        return val;
    }
    format = format || 'LLL';
    try {
        //create a moment date with string
        let date = new Date(val);
        if (format) {
            //check if date has hours, minutes, seconds
            if (val.length === 10) {
                let m = moment.utc(val, "YYYY-MM-DD");
                m = m.local();
                return m.format(format);
            }
            let m = moment.utc(val, "YYYY-MM-DD HH:mm:ss");
            m = m.local();
            return m.format(format);
            // return moment(date).format(format);
        }
        return date.toLocaleString();
    } catch (e) {
        return val;
    }

}

export const tableSortIconHeaderArrow = (desc: boolean) => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
    }> {desc ? <MoveDown size={12}/> : <MoveUp size={12}/>}</div>;
}

export const tableSortIconHeaderArrow2 = (direction: string | null) => {
    if (!direction) {
        return null;
    }
    return direction === 'asc' ? <MoveDown size={12}/> : <MoveUp size={12}/>;
}

export const sortItems = (sortBy: string, items: any) => {

    if (sortBy == null) {
        return;
    }

    let direction = sortBy.endsWith('_desc') ? -1 : 1;
    // const directionString = direction === 1 ? 'asc' : 'desc';
    let field = sortBy.replace('_desc', '');

    //sort by field, case insensitive, if numbers, sort by number


    items.sort((a: any, b: any) => {


            // const aValue = a[field];
            // const bValue = b[field];
            //
            // const aNum = isNaN(Number(aValue)) ? aValue : Number(aValue);
            // const bNum = isNaN(Number(bValue)) ? bValue : Number(bValue);
            //
            // if (aNum < bNum) return directionString === 'asc' ? -1 : 1;
            // if (aNum > bNum) return directionString === 'asc' ? 1 : -1;


            if (a[field] == null || a[field] === '' || a[field] === 'null') {
                return -1 * direction;
            }

            if (typeof a[field] === 'number' && typeof b[field] === 'number') {
                return (a[field] - b[field]) * direction;
            }
            if (a[field]?.toString().toLowerCase() < b[field]?.toString().toLowerCase()) {
                return -1 * direction;
            }
            if (a[field]?.toString().toLowerCase() > b[field]?.toString().toLowerCase()) {
                return direction;
            }
            return 0;
        }
    );
    //move all null values to the bottom
    items.sort((a: any, b: any) => {
        if (a[field] == null || a[field] === '' || a[field] === 'null') {
            return 1;
        }
        if (b[field] == null || b[field] === '' || b[field] === 'null') {
            return -1;
        }
        return 0;
    });


}