import {useRef} from "react";
import {DSYCampaign} from "../../models/DSYCampaign";
import {friendlyPlatformImage, friendlyPlatformName} from "../../config/consts";


export function CampaignsBulkUploader({onClose, editingCampaign}: {
    onClose: (
        items: string[] | undefined
    ) => void,
    editingCampaign: DSYCampaign
}) {

    const refBulkImport = useRef<HTMLTextAreaElement>(null);


    const handleBulkImport = async (e: any) => {
        e.preventDefault();

        let text = (refBulkImport.current?.value ?? '').split('\n');
        let updatedItems: any[] = [];
        for (let x in text) {
            let username = text[x].toString().trim();
            if (username === '') {
                continue;
            }
            //add to items if not already there
            if (!updatedItems.includes(username)) {
                updatedItems.push(username);
            }
        }
        onClose(updatedItems);
    }


    return (
        <>
            <div>
                <h3>Campaigns Bulk List Users</h3>
                <div style={{
                    margin: '10px 0',
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                }}>
                    <img
                        height="30"
                        src={friendlyPlatformImage(editingCampaign.platforms_id)}
                        alt={friendlyPlatformName(editingCampaign.platforms_id)}/>
                    <p>Paste in a list of {
                        friendlyPlatformName(editingCampaign.platforms_id)
                    } usernames, one per line, and they will be added to the campaign.</p>
                </div>
                <textarea
                    ref={refBulkImport}
                    style={{
                        width: '100%',
                        height: '130px',
                        border: '1px solid #333'
                    }}
                />
                <div>
                    <button onClick={() => onClose(undefined)}>Cancel</button>
                    <button onClick={handleBulkImport}>Submit Users</button>
                </div>
            </div>
        </>
    );
}